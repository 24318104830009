import * as Sentry from '@sentry/browser';

export default function initSentry(component) {
  if (process.env.SENTRY_DSN && process.env.SOURCE_VERSION) {
    Sentry.init({
      dsn: process.env.SENTRY_DSN,
      release: process.env.SOURCE_VERSION,
      environment: process.env.ENVIRONMENT
    });

    Sentry.setTag('component', component);

    try {
      const id = window.localStorage['__bio_demo_id'] = (
        window.localStorage['__bio_demo_id'] || '_' + Math.random()
      );

      Sentry.setUser({ id: id });
    } catch (err) {

      // ignore
    }
  }
}