const trackFallback = (
  process.env.ENVIRONMENT === 'development'
    ? function log(category, action, label) {
      console.info('development:track', category, action, label);
    }
    : function() { }
);

const track = global.track = global.track || trackFallback;

export default track;