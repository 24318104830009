export function toggleFullscreen(element) {
  var toggle = document.fullscreenElement
    ? document.exitFullscreen()
    : element.requestFullscreen();

  return toggle.catch(err => {
    console.warn('fullscreen toggle failed', err);
  });
}

export function isFullScreenSupported() {
  return !!document.requestFullscreen;
}